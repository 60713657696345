import { get, post } from "@/utils/https";
import qs from "qs";
// 登录验证码
export function smsLgcode(mobile, params) {
  return get(
    `/api/v1/common/saas/app/offline/apply/loginSmsCode/${mobile}`,
    params
  );
}

// 注册(登录)
export function login(data) {
  let authToken = window.btoa(
    `${process.env.VUE_APP_AUTH_CLIENT_ID}:${process.env.VUE_APP_AUTH_CLIENT_SECRET}`
  );
  let headrC = {
    noLogin: true,
    "content-type": "application/x-www-form-urlencoded",
    platform: process.env.VUE_APP_AUTH_PLATFORM,
    Authorization: "Basic " + authToken
  };
  // 如果不用qs转一下，会报前端跨域
  let userData = qs.stringify({
    grant_type: "sms",
    scope: "userProfile",
    ...data
  });
  return post(`/login/sms/token`, userData, null, headrC);
}

// 登录以后获取用户信息
export function getUserInfo() {
  return get(`/api/v1/common/saas/app/offline/apply/login/customer`);
}

// 产品信息
export function productInfos(productNo) {
  if (!productNo) throw "缺少productNo";
  return get(`/api/v1/common/saas/app/offline/apply/product/base/${productNo}`);
}

// 产品配置
export function productConfigs(productNo) {
  return get(`/api/v1/common/saas/app/offline/apply/field/${productNo}`);
}

// 获取所有产品字段枚举
export function getAllEnums() {
  return get(`/api/v1/common/saas/app/offline/apply/enums`);
}

// 创建订单
export function createOrder(data) {
  return post(`/api/v1/common/saas/app/offline/apply/create`, data);
}

// 发送授权验证码
export function authCode(data) {
  return post(`/api/v1/common/saas/app/offline/apply/signSmsCode`, data);
}
// 确认函授
export function checkedAuthCode(data) {
  return post(`/api/v1/common/saas/app/offline/apply/checkSmsCode`, data);
}

// 个人申请书 personApplyPdf
export function personApplyPdf(bizNo) {
  return get(`/api/v1/common/saas/app/offline/apply/getPersonSign/${bizNo}`);
}

// 四要素认证 验证码

export function authSmsCode(mobile) {
  return get(`/api/v1/common/saas/app/offline/apply/authSmsCode/${mobile}`);
}
//  四要素认证
export function fourElements(data) {
  return post(`/api/v1/common/saas/app/offline/apply/auth/fourElements`, data);
}

//  进件融资申请状态信息
export function orderDetail(bizNo) {
  if (!bizNo) throw "缺少bizNo";
  return get(`/api/v1/common/saas/app/offline/apply/apply/order/${bizNo}/desc`);
}
//  根据productNo，merchantNo，查询该产品最新一个进件订单的进件融资申请状态信息
export function orderDetailLatest(productNo, merchantNo) {
  if (!productNo) throw "缺少productNo";
  if (!merchantNo) throw "缺少merchantNo";
  return post(
    `api/v1/common/saas/app/offline/apply/apply/${productNo}/${merchantNo}/latest`
  );
}

// 通过bizNo查询MerchantNo和ProductNo
export function getLoginInfoByBizNo(bizNo) {
  if (!bizNo) throw "缺少bizNo";
  return get(`/api/v1/common/saas/app/offline/apply/login/${bizNo}`);
}

// 产品配置影像资料列表 by 产品no
export function getProductMaterialConfig(productNo) {
  if (!productNo) throw "缺少productNo";
  return get(`/api/v1/common/saas/app/offline/apply/images/${productNo}`);
}

// 产品配置影像资料列表 by bizNo
export const getProductMaterialConfigByBizNo = async (bizNo) => {
  if (!bizNo) throw "缺少bizNo";
  return await get(`/api/v1/common/saas/app/offline/apply/images/${bizNo}`);
};

// 获取影像资料列表 by bizNo
export const getImagesByBizNo = async (bizNo) => {
  if (!bizNo) throw "缺少bizNo";
  return await get(
    `/api/v1/common/saas/app/offline/apply/apply/findImages/${bizNo}`
  );
};

//  保存进件材料(new) - 单个保存
export function saveApplyImagesNew(data) {
  if (!data.bizNo) throw "缺少bizNo";
  return post(`/api/v1/common/saas/app/offline/apply/apply/saveImages`, data);
}

//  保存进件材料(new) - 保存
export function saveApplyImagesConfirm(bizNo) {
  if (!bizNo) throw "缺少bizNo";
  return post(
    `/api/v1/common/saas/app/offline/apply/apply/imagesConfirm/${bizNo}`
  );
}

//  保存进件材料
export function saveApplyImages(data) {
  if (!data.bizNo) throw "缺少bizNo";

  return post(
    `/api/v1/common/saas/app/offline/apply/apply/images/${data.bizNo}/save`,
    data.imgs
  );
}

// 获取客服二维码
export function getServiceQRCode(no) {
  if (!no) throw "订单号错误";
  return get(
    `/api/v1/common/saas/app/offline/apply/customer/service/config/${no}`
  );
}

// 认证流程查询
export function verifyFollowStatus(no) {
  if (!no) throw "订单号错误";
  return get(
    `/api/v1/common/saas/app/offline/apply/user/verification/config/list/${no}`
  );
}
// ocr识别
export function ocrIdentify(data) {
  if (!data.bizNo) throw "订单号错误";
  return post(
    `/api/v1/common/saas/app/offline/apply/idcard/ocr/${data.bizNo}`,
    data
  );
}
// 识别提交认证
export function ocrIdentifySubmit(data) {
  if (!data.bizNo) throw "订单号错误";
  return post(
    `/api/v1/common/saas/app/offline/apply/identity/authentication/${data.bizNo}`,
    data
  );
}

// 人脸对比
export function faceCompare({ bizNo, url }) {
  if (!bizNo) throw "订单号错误";
  return post(`/api/v1/common/saas/app/offline/apply/face/idcard/${bizNo}`, {
    url
  });
}
// 上传
export async function uploadFile(data) {
  try {
    const res = await post("/api/v4/nicp/files/file", data, {
      platform: process.env.VUE_APP_AUTH_PLATFORM,
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    });

    if (!res.data) throw "暂无数据";

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
//  根据productNo，merchantNo，查询该产品最新一个进件订单的进件融资申请状态信息(预进件)
export function orderDetailLatestv2(productNo, merchantNo) {
  if (!productNo) throw "缺少productNo";
  if (!merchantNo) throw "缺少merchantNo";
  return post(
    `/api/v1/common/saas/app/offline/apply/apply/${productNo}/${merchantNo}/latest`
  );
}
export function localtionStatus(productNo) {
  return get(
    `/api/v1/common/saas/app/offline/apply/income/location/status/${productNo}`
  );
}

// 识别提交认证（预进件）
export function ocrIdentifySubmitv2(data) {
  if (!data.preOrderNo) throw "预进件订单号错误";
  return post(
    `/api/v1/common/saas/app/offline/apply/identity/authentication/${data.preOrderNo}`,
    data
  );
}

// 身份证识别（预进件）
export function ocrIdentifyv2(data) {
  if (!data.preOrderNo) throw "预进件订单号错误";
  return post(
    `/api/v1/common/saas/app/offline/apply/ocr-id-card/${data.preOrderNo}`,
    data
  );
}
// 银行卡识别（预进件）
export function ocrBackcard(data) {
  if (!data.preOrderNo) throw "预进件订单号错误";
  return post(
    `/api/v1/common/saas/app/offline/apply/ocr_bank_card/${data.preOrderNo}`,
    data
  );
}
// 行驶证识别（预进件）
export function ocrLicense(data) {
  if (!data.preOrderNo) throw "预进件订单号错误";
  return post(
    `/api/v1/common/saas/app/offline/apply/ocr-driving-licence/${data.preOrderNo}`,
    data
  );
}
// 创建订单（预进件）
export function applyCreate(data) {
  if (!data.preOrderNo) throw "预进件订单号错误";
  return post(`/api/v1/common/saas/app/offline/apply/create`, data);
}
// 字段模块提交
export function fieldsFillSubmit(data) {
  return post(
    `/api/v1/common/saas/app/offline/apply/incoming/qualification/perfection/${data.bizNo}`,
    data
  );
}
